import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { completeQuest, fetchQuests } from "./api";
import { Quest } from "./types";
import axios from "axios";
import { io } from "socket.io-client";
import { API_SERVER } from "../../util/constants";
const socket = io(API_SERVER);

const CallbackComponent: React.FC = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const provider = queryParams.get("provider");
  const [message, setMessage] = useState(
    `Handling ${provider} Authentication... Please wait.`
  );
  const uuidFromParam = new URL(window.location.href).searchParams.get(
    "uuid"
  ) as string | null;
  const [quest, setQuest] = useState<Quest | null>(null);
  const [isLinked, setIsLinked] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const isCompleted = useRef(false);
  useEffect(() => {
    const initializeData = async () => {
      try {
        const groups = await fetchQuests(uuidFromParam);

        let foundQuest: Quest | undefined = undefined;
        const questUni = provider === "twitter" ? "twitterAuth" : "discordAuth";
        for (const group of groups) {
          foundQuest = group.quests.find((q) => q.uni === questUni);
          if (foundQuest) break;
        }

        if (foundQuest) {
          setQuest(foundQuest);
        } else {
          throw new Error("Quest not found");
        }
      } catch (error: any) {
        console.error("Error initializing data:", error.message);
        console.error("Error stack trace:", error.stack);
        setIsError(true);
      }
    };
    if (uuidFromParam) {
      initializeData();
    }
  }, [uuidFromParam, provider]);

  useEffect(() => {
    const completeAuthProcess = async () => {
      if (uuidFromParam && quest && !isCompleted.current) {
        try {
          const result = await completeQuest(quest?.id, uuidFromParam);
          if (result.completed_at) {
            setMessage(`Yeah! You've linked your ${provider} account.`);
            setIsLinked(true);
            socket.emit("complete_quest", { sender: "User", content: quest });
            isCompleted.current = true;
          }
        } catch (error) {
          console.error("Failed to complete authentication:", error);
          setMessage(
            `Failed to link your ${provider} account. Please try again.`
          );
          setIsError(true);
        }
      }
    };

    if (uuidFromParam && quest) {
      completeAuthProcess();
    }
  }, [provider, navigate, uuidFromParam, quest]);
  useEffect(() => {
    socket.on("quest_completed", () => {
      window.close();
    });
    return () => {
      socket.off("quest_completed");
    };
  }, []);
  function getCookie(name: string) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  // useEffect(() => {
  //   if (isLinked) {
  //     window.close();
  //   }
  // }, [isLinked]);
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#181818",
        color: "#FFFFFF",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h1>{message}</h1>
      {/* {isLinked ? (
        <button
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
            marginTop: "20px",
          }}
          onClick={() => navigate(`/?uuid=${uuidFromParam}`)}
        >
          Go Back to Quests
        </button>
      ) : (
        isError && (
          <button
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              marginTop: "20px",
            }}
            onClick={() => navigate(`/?uuid=${uuidFromParam}`)}
          >
            Try Again
          </button>
        )
      )} */}
    </div>
  );
};

export default CallbackComponent;
