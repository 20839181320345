import styled from "styled-components";
import bulb_url from "../../assets/bulb.png";
import default_bg from "../../assets/daily_dose.png";
import React from "react";

const Card = styled.div`
  border-radius: 8px;
  /* background: #d44646; */
  margin-bottom: 19px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  padding-block: 13px;
  padding-inline-start: 17px;
  padding-inline-end: 15px;
  display: flex;
  align-items: center;
`;

const NotificationIcon = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 7px;
`;

const NotificationText = styled.div`
  color: #f5f5f5;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;

  @media (max-width: 1380px) {
    display: none;
  }
  @media (max-width: 1200px) {
    display: block;
  }
`;

const ShortNotificationText = styled(NotificationText)`
  display: none;

  @media (max-width: 1380px) {
    display: block;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

const NotificationButton = styled.button`
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  border-radius: 6px;
  background: #6c0d0d;
  margin-left: 14px;
  height: 30px;
  padding-inline: 15px;
  min-width: 128px;
  cursor: pointer;
`;

const Notification = () => {
  return (
    <Card style={{ backgroundImage: `url(${default_bg})` }}>
      <Content>
        <NotificationIcon src={bulb_url} alt="bulb" />
        <NotificationText>
          Hey it seems that Santa is not a default browser on your systems. Set
          as default browser and earn <span>30</span> pts now!
        </NotificationText>
        <ShortNotificationText>
          Set as default browser and earn <span>30</span> pts now!
        </ShortNotificationText>
        <NotificationButton>Set as Default</NotificationButton>
      </Content>
    </Card>
  );
};

export default Notification;
