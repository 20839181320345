import discord_url from "../../assets/discord.png";
import mini_santa_logo from "../../assets/mini_santa_logo.png";
export type Subquest = {
  id: number;
  title: string;
  description: string;
};

export type Quest = {
  id: number;
  type: string;
  desc: string;
  points?: number;
  category?: string;
  actionWord?: string;
  icon?: string;
  link?: string;
  subquests?: Subquest[];
};

export const quests: Quest[] = [
  {
    id: 1,
    type: "Twitter Quests",
    desc: "Hey follow us on Twitter for the latest updates & features",
    actionWord: "Follow now on X",
    icon: "",
    category: "Social",
    points: 150,
    link: "https://rewards.santabrowser.com/twitter/auth",
    subquests: [
      {
        id: 1,
        title: "Twitter Tag",
        description: "Complete actions related to tagging on Twitter.",
      },
      {
        id: 2,
        title: "Twitter Auth",
        description: "Authenticate your account for Twitter integration.",
      },
    ],
  },
  {
    id: 2,
    type: "Discord Quests",
    desc: "Bug Bounty is here ! Find Bugs share and earn more points",
    points: 150,
    category: "Social",
    actionWord: "Share on Discord",
    icon: discord_url,
    link: "https://rewards.santabrowser.com/discord/auth",
    subquests: [
      {
        id: 1,
        title: "Is Member of Server",
        description: "Verify membership in a specific Discord server.",
      },
      {
        id: 2,
        title: "Discord Auth",
        description: "Authenticate your account for Discord integration.",
      },
    ],
  },
  {
    id: 3,
    type: "Default Browser Quests",
    desc: "Set Santa as default browser for a month and earn more points",
    points: 100,
    category: "Quest",
    actionWord: "Set as Default",
    icon: mini_santa_logo,
  },
];

export default quests;
