import React from "react";
import styled from "styled-components";
import op_icon_url from "../../assets/op.svg";
import quest_sample_url from "../../assets/quest_sample.png";
import { CampaignNode } from "./world-quests";

const Container = styled.div`
  position: relative;
  border-radius: 10px;
  background-color: #1f1f1f;
  /* max-width: 204px; */
  height: 110px;
  display: flex;
  flex-direction: column;
  /* padding-block-end: 13px; */
  cursor: pointer;
  background-clip: padding-box;
  transition: all 0.3s ease;
  flex: 1;

  &::after {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 10px;
    content: "";
    z-index: -1;
    background-image: radial-gradient(
      circle at center,
      #1a1a1a 0%,
      #6f6f6f 5%,
      #1a1a1a 100%
    );
    opacity: 0; // Start fully transparent
  }

  &:hover {
    background-color: #1b1b1b;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    &::after {
      opacity: 1;
    }
  }
`;

const HeadContent = styled.div`
  border-radius: 8px 8px 0 0;
  padding: 10px 10px 0 0;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0 10px 0 10px;
  flex: 1;
  gap: 15px;
`;

const Earnings = styled.div`
  border-radius: 4px;
  border: 1px solid #ffbe05;
  background: #181818;
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 50px;
`;

const EarningsSpan = styled.span`
  color: #ffbe05;
`;

const Chains = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
`;

const Chain = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

const ChainName = styled.div`
  color: #ffbe05;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 6px;
`;

const Task = styled.div`
  width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b9b9b9;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  /* padding: 0 10px 0px 10px; */
`;
interface QuestProps {
  campaign: CampaignNode;
}
const Quest: React.FC<QuestProps> = ({ campaign }) => {
  return (
    <Container>
      <HeadContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "90px",
            justifyContent: "space-between",
          }}
        >
          <Task>{campaign.distributionType}</Task>
          <Earnings>
            {/* <EarningsSpan>XP</EarningsSpan> 100 */}
            {campaign?.type}
          </Earnings>
        </div>
      </HeadContent>
      <Content>
        <img
          src={campaign.space.thumbnail}
          alt={`${campaign.space.name}`}
          width={52}
          height={52}
          style={{ borderRadius: "50%", objectFit: "contain" }}
        />
        <div>
          <Chains>
            <Chain>
              {/* <img
                src={`https://www.google.com/s2/favicons?domain=${campaign.space.links}&sz=128`}
                alt={`${campaign.space.links}`}
                height={15}
                width={15}
              /> */}
              <ChainName>{campaign.space.name}</ChainName>
            </Chain>
          </Chains>
          <Description>{campaign.name}</Description>
        </div>
      </Content>
    </Container>
  );
};

export default Quest;
