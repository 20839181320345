import { useEffect, useState } from "react";
import { Group, Quest, CompleteQuestResponse, UserRewards } from "./types"; // Assume these types are defined in types.ts
import axios from "axios";

const BASE_URL = "https://rewards.santabrowser.com/api";

export async function fetchQuests(uuid: string | null): Promise<Group[]> {
  const response = await fetch(
    `${BASE_URL}/quests/list?platform=desktop&clid=${uuid}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch quests");
  }
  return response.json();
}

export async function completeQuest(
  questId: string,
  uuid: string | null
): Promise<CompleteQuestResponse> {
  const response = await fetch(`${BASE_URL}/quests/complete`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userClid: uuid, questId }),
  });
  if (!response.ok) {
    throw new Error("Failed to complete quest");
  }
  return response.json();
}

export async function fetchUserRewards(
  uuid: string | null
): Promise<UserRewards> {
  const response = await fetch(`${BASE_URL}/users/rewards?clid=${uuid}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user rewards");
  }
  return response.json();
}

export const useFetchEarnings = (uuid: string | null) => {
  const [data, setData] = useState<UserRewards | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (!uuid) return;

    const fetchEarnings = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/users/rewards?clid=${uuid}`
        );
        setData(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchEarnings();
  }, [uuid]);

  return { data, isLoading, error };
};
