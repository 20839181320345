import styled from "styled-components";
import refer_earn_icon from "../../assets/refer_earn.png";

const Card = styled.div`
  border-radius: 8px;
  /* border: thin solid #FFBE05; */
  background: #262626;
  /* min-width: 318px; */
  color: #f5f5f5;
  padding-block: 15px;
  padding-inline: 10px;
  /* padding-inline-end: 22.5px; */
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 79.5px; */
  height: 100%;
  justify-content: space-between;
`;

const TopContent = styled.div`
  display: flex;
  gap: 7.33px;
  align-items: flex-start;
`;

const TopContentTitle = styled.div`
  color: #ffbe05;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const TopContentSubtitle = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  color: #ffbe05;
`;

const Points = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  > span {
    color: #ffbe05;
    font-size: 22px;
    font-weight: 600;
    line-height: 16px;
  }
`;

const ReferralLink = styled.div`
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const ReferTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type ReferAndEarnType = {
  title: string;
  description: string;
  points: number;
  action: string;
};

const ReferEarn = ({ data }: { data: ReferAndEarnType }) => {
  return (
    <Card>
      <Container>
        <TopContent>
          <img
            src={refer_earn_icon}
            alt="Refer and Earn"
            width={65}
            height={47.3}
            style={{ objectFit: "contain" }}
          />
        </TopContent>
        <ReferTextBox>
          <TopContentTitle>Refer and Earn</TopContentTitle>
          <TopContentSubtitle>
            Refer your friends and earn points!
          </TopContentSubtitle>
          <Points>
            <span>{data.points}</span> pts for{" "}
            <span style={{ fontSize: "14px", fontWeight: "400" }}>Each</span>{" "}
            referal
          </Points>
        </ReferTextBox>
        <BottomContent>
          <ReferralLink>Copy Referral Link</ReferralLink>
        </BottomContent>
      </Container>
    </Card>
  );
};

export default ReferEarn;
