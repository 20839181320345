import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { QuestPoints } from "./quests";
import { Quest } from "../dashboard/constants";
import { Group, Quest as Questy } from "../dashboard/types";
import rightChevron from "../../assets/right_cheveron.svg";
import { completeQuest } from "../dashboard/api";
import defaultBg from "../../assets/default-bg.png";
import axios from "axios";
const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  btnColor,
}) => {
  if (!isOpen) return null;
  return (
    <ModalBackdrop onClick={onClose}>
      <ModalBackButton
        onClick={onClose}
        style={{
          backgroundColor:
            btnColor === "telegram"
              ? "#29B6F6"
              : btnColor === "discord"
              ? "#5F70BE"
              : "#ffffff",
          borderColor:
            btnColor === "telegram"
              ? "#0075AB"
              : btnColor === "discord"
              ? "#6B80E3"
              : "#1b1b1b",
        }}
      >
        {/* 
style={{
                  color:
                    questGroup.category.toLocaleLowerCase() === "twitter"
                      ? "#ffffff"
                      : questGroup.category.toLocaleLowerCase() === "telegram"
                      ? "#28A8E7"
                      : questGroup.category.toLocaleLowerCase() === "discord"
                      ? "#5F70BE"
                      : "#ffffff",
                  fontWeight: "600",
                }} */}
        <img src={rightChevron} alt="" />
      </ModalBackButton>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalBackdrop>
  );
};

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  z-index: 1000;
`;

const ModalContent = styled.div`
  border-radius: 5px 0px 0px 5px;
  background: linear-gradient(180deg, #181818 21.66%, #1b1b1b 100%);
  box-shadow: -10px 4px 25px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 25px 25px 20px 25px;
  max-width: 500px;
  width: 90%;
  height: 100%;
`;

const ModalBackButton = styled.button`
  margin-top: 15px;
  width: 22px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #991e0d;
  background: #de5947;
`;

const Container = styled.div`
  position: relative;
  border-radius: 8px;
  background: #262626;
  height: 100%;
  padding: 20px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  gap: 20px;
  flex: 1;
  /* overflow: hidden; */
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15.33px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Type = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-radius: 4px;
  border: 0.75px solid #86372c;
  background: #611515;
  padding: 4px 8px;
`;

const Campaigns = styled.div`
  position: relative;
  z-index: 5;
  border-radius: 8px;
  /* border: 1px solid #505050;
  background: #2c2c2c; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 20px;
`;

const Campaign = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CampaignTitle = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
  text-transform: capitalize;
  @media (max-width: 645px) {
    white-space: normal;
  }
`;

const TotalCampaigns = styled.div`
  display: flex;
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.26);
  padding: 12px 10px;
  align-items: center;
  gap: 2px;
`;

const Description = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const QuestItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

const QuestTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  p {
    text-decoration: underline;
  }

  span {
    color: #72b569;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
  }
`;
const ModalQuestsBox = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const QuestWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding: 25px 20px 20px 20px;
  gap: 50px;
  border-radius: 8px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Darken overlay: adjust opacity as needed */
    z-index: 1;
    border-radius: 8px;
  }
`;
const QuestWrapTop = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
interface QuestCardProps {
  quest?: Quest;
  platformQuests?: Questy[];
  questGroup?: Group;
  uuid?: string | null;
  addPoints?: (points: number) => void;
  updateCompletedQuests?: () => void;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  btnColor: string;
}

const QuestCard: React.FC<QuestCardProps> = ({
  quest,
  platformQuests,
  questGroup,
  uuid,
  addPoints,
  updateCompletedQuests,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [dropdownMarginTop, setDropdownMarginTop] = useState(0);
  const campaignsRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [quests, setQuests] = useState(questGroup?.quests);
  const toggleModal = () => {
    const message = { type: "MODAL_STATE_CHANGE", modalOpen: !modalIsOpen };
    window?.top?.postMessage(JSON.stringify(message), "*");
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight ?? 0;
    const campaignsHeight = campaignsRef.current?.offsetHeight ?? 0;
    setDropdownMarginTop(headerHeight + campaignsHeight);
  }, [headerRef, campaignsRef]);
  const handleQuestClick = (link: string | undefined) => {
    if (link && uuid) {
      const urlWithUuid = `${link}&uuid=${uuid}`;
      const popup = window.open(
        urlWithUuid,
        "popup",
        "width=800,height=600,left=100,top=100"
      );
      popup?.postMessage("message", "*");
    }
  };

  const handleTelegramAuth = async (quest: Questy) => {
    //@ts-ignore
    window.Telegram.Login.auth(
      { bot_id: "7127924172", request_access: true },
      async function (data: any) {
        if (data) {
          if (!quest.done) {
            const result = await completeQuest(quest.id, uuid ? uuid : "");

            if (result.completed_at) {
              setQuests((prev) => {
                return prev?.map((q) =>
                  q.id === quest.id ? { ...q, done: true } : q
                );
              });
              if (addPoints) {
                addPoints(Number(quest.reward));
              }
              if (updateCompletedQuests) updateCompletedQuests();
            } else {
              console.error("Failed to complete quest on server.");
            }
          }
        } else {
          console.error("Telegram authentication failed.");
        }
      }
    );
  };

  useEffect(() => {
    setQuests(questGroup?.quests);
  }, [questGroup]);

  const handleCheckTweet = async (item: Questy) => {
    try {
      const response = await axios.get(
        "https://rewards.santabrowser.com/check-for-mention",
        { withCredentials: true }
      );
      const isTagged = response.data.mentionFound;
      if (isTagged) {
        const result = await completeQuest(item.id, uuid ? uuid : "");
        if (result.completed_at) {
          setQuests((prev) => {
            return prev?.map((q) =>
              q.id === item.id ? { ...q, done: true } : q
            );
          });
          if (addPoints) {
            addPoints(Number(item.reward));
          }
          if (updateCompletedQuests) updateCompletedQuests();
        } else {
          console.error("Failed to complete quest on server.");
        }
      } else {
        handleQuestClick(item.link);
      }
    } catch (error) {
      handleQuestClick(item.link);
      console.error("Failed to check for Twitter mention:", error);
    }
  };

  const handleCheckDiscordMembership = async (item: Questy) => {
    try {
      const response = await axios.get(
        "https://rewards.santabrowser.com/check-membership",
        { withCredentials: true }
      );
      const isUserMember = response.data.isMember;
      if (isUserMember) {
        const result = await completeQuest(item.id, uuid ? uuid : "");
        if (result.completed_at) {
          setQuests((prev) => {
            return prev?.map((q) =>
              q.id === item.id ? { ...q, done: true } : q
            );
          });
          if (addPoints) {
            addPoints(Number(item.reward));
          }
          if (updateCompletedQuests) {
            updateCompletedQuests();
          }
        } else {
          console.error("Failed to complete quest on server.");
        }
      } else {
        window.open(
          item.link,
          "popup",
          "width=800,height=600,left=100,top=100"
        );
      }
    } catch (error) {
      window.open(item.link, "popup", "width=800,height=600,left=100,top=100");
      console.error("Failed to check Discord membership:", error);
    }
  };

  return (
    <Container
      style={{
        backgroundImage: questGroup
          ? `url(https://rewards.santabrowser.com/api/upload/${questGroup.preview})`
          : `url(${defaultBg})`,
      }}
      onClick={questGroup ? toggleModal : undefined}
    >
      {questGroup ? (
        <>
          <Content>
            <Campaigns>
              <Campaign>
                <img
                  src={`https://rewards.santabrowser.com/api/upload/${questGroup?.icon}`}
                  alt="twitter"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain" }}
                />
                <CampaignTitle>{questGroup?.title}</CampaignTitle>
              </Campaign>

              <TotalCampaigns>
                {`${questGroup?.quests?.length} ${
                  questGroup?.quests?.length === 1 ? "Campaign" : "Campaigns"
                }`}
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" >"}
                </div>
              </TotalCampaigns>
            </Campaigns>
            <Header ref={headerRef}>
              <QuestPoints>
                {`${questGroup?.quests.reduce(
                  (acc, quest) => acc + Number(quest.reward),
                  0
                )} pts`}
              </QuestPoints>
              {/* <Type>{questGroup?.category}</Type> */}
            </Header>
            <Description>{questGroup?.description}</Description>
          </Content>
          <QuestItem>
            <img
              src={`https://rewards.santabrowser.com/api/upload/${questGroup?.icon}`}
              alt={`${questGroup?.category}`}
              width={20}
              height={20}
            />
            <QuestTitle
              onClick={(e) => {
                e.stopPropagation();
                handleQuestClick(quest?.link);
              }}
              style={{ textDecoration: "underline" }}
            >
              {quest ? quest?.actionWord : "Join on Telegram"}
            </QuestTitle>
          </QuestItem>
          <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            btnColor={questGroup.category.toLocaleLowerCase()}
          >
            <Campaign>
              <img
                src={`https://rewards.santabrowser.com/api/upload/${questGroup?.icon}`}
                alt="twitter"
                width={30}
                height={30}
                style={{ objectFit: "contain" }}
              />
              <CampaignTitle
                style={{
                  color:
                    questGroup.category.toLocaleLowerCase() === "twitter"
                      ? "#ffffff"
                      : questGroup.category.toLocaleLowerCase() === "telegram"
                      ? "#28A8E7"
                      : questGroup.category.toLocaleLowerCase() === "discord"
                      ? "#5F70BE"
                      : "#ffffff",
                  fontWeight: "600",
                }}
              >
                {questGroup?.title}
              </CampaignTitle>
            </Campaign>
            <ModalQuestsBox>
              {quests?.map((item, key) => (
                <QuestWrap
                  key={key}
                  style={{
                    backgroundImage: `url(https://rewards.santabrowser.com/api/upload/${questGroup.preview})`,
                  }}
                >
                  <QuestWrapTop>
                    <Description>{item.title}</Description>
                    <QuestPoints
                      style={{ backgroundColor: "rgba(24, 24, 24, 0.38)" }}
                    >
                      {item.reward}pts
                    </QuestPoints>
                  </QuestWrapTop>

                  <QuestTitle
                    style={{
                      cursor: "pointer",
                      textAlign: "right",
                      position: "relative",
                      zIndex: "2",
                      color: "rgba(255, 255, 255, 0.80)",
                      pointerEvents: item.done ? "none" : "auto",
                    }}
                    onClick={
                      item.uni === "twitterTagging"
                        ? () => handleCheckTweet(item)
                        : item.uni === "discordMember"
                        ? () => handleCheckDiscordMembership(item)
                        : quest
                        ? () => handleQuestClick(item?.link)
                        : () => handleTelegramAuth(item)
                    }
                  >
                    {item.done ? <span>Completed</span> : <p>{item.go}</p>}
                  </QuestTitle>
                </QuestWrap>
              ))}
            </ModalQuestsBox>
          </Modal>
        </>
      ) : (
        <>
          <Content>
            <Header ref={headerRef}>
              <QuestPoints>{`${quest?.points} pts`}</QuestPoints>
              <Type>{quest?.category}</Type>
            </Header>
          </Content>
          <Description>
            {quest?.desc.split(" ").map((word, index) => (
              <span
                key={index}
                style={{
                  color: word.toLowerCase() === "santa" ? "#DE5947" : "#ffffff",
                  fontStyle: "italic",
                }}
              >
                {word + (index < quest.desc.split(" ").length - 1 ? " " : "")}
              </span>
            ))}
          </Description>
          <QuestItem>
            <img src={quest?.icon} alt="default" width={20} height={20} />
            <QuestTitle
              onClick={() => handleQuestClick(quest?.link)}
              style={{ textDecoration: "underline" }}
            >
              {quest?.actionWord}
            </QuestTitle>
          </QuestItem>
        </>
      )}
    </Container>
  );
};

export default QuestCard;
