/* eslint-disable no-mixed-operators */
import React, { ReactNode, useEffect } from "react";
import "./dashboard.css";
import ReferEarn from "../earnings-region/refer-earn";
import Earnings from "../earnings-region/earnings";
import Notification from "../earnings-region/notification";
import Leaderboard from "../earnings-region/leaderboard";
import QuestCard from "../earn-more-region/quest-card";
import Quests from "../earn-more-region/quests";

import sample_data from "../../sample.json";
import Icon from "../../Icon";
import svgs from "../../svgs";
import cup from "../../assets/cup.png";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import quests from "./constants";
import WorldQuests from "../world-quests-region/world-quests";
import { Group, Quest } from "./types";
import { completeQuest, useFetchEarnings } from "./api";
import { deleteItem, getItem } from "../utils/indexedDBUtils";

import { io } from "socket.io-client";
import { API_SERVER } from "../../util/constants";
const socket = io(API_SERVER);

type RegionHeaderProps = {
  title: string;
  subtitle: string;
  children?: ReactNode;
  shownav?: boolean;
};
const RegionHeader = (props: RegionHeaderProps) => {
  return (
    <div className="region-header">
      <div className="region-header-text">
        <div className="region-header-title">{props.title}</div>
        <div className="region-header-subtitle">{props.subtitle}</div>
      </div>
      {/* {!!props.shownav && <NavigatorComponent />} */}
    </div>
  );
};

const Dashboard = () => {
  const [fetchingEarnMore, setFetchingEarnMore] = React.useState(true);
  const [fetchingLeaderboard, setFetchingLeaderboard] = React.useState(false);

  const [earnMoreData, setEarnMoreData] = React.useState<Group[]>([]);
  const [leaderboardData, setLeaderboardData] = React.useState<any>([]);
  const [uuid, setUuid] = React.useState<string | null>(null);
  const [twitterGroup, setTwitterGroup] = React.useState<Group>();
  const [discordGroup, setDiscordGroup] = React.useState<Group>();
  const [telegramGroup, setTelegramGroup] = React.useState<Group>();
  const [completedQuests, setCompletedQuests] = React.useState<Quest[]>([]);
  const {
    data: earningsData,
    isLoading: fetchingEarnings,
    error,
  } = useFetchEarnings(uuid);
  const [totalSantaPoints, setTotalSantaPoints] = React.useState(0);

  useEffect(() => {
    if (earningsData && earningsData.sp_earned !== undefined) {
      setTotalSantaPoints(earningsData.sp_earned);
    }
  }, [earningsData]);
  const addPoints = (points: number) => {
    setTotalSantaPoints((prevPoints) => prevPoints + points);
  };
  const fetchLeaderboard = async () => {
    setFetchingEarnMore(true);

    await axios
      .get(`https://rewards.santabrowser.com/api/quests/leaderboard`)
      .then((res) => {
        if (res.data) {
          setLeaderboardData(res.data);
          setFetchingLeaderboard(false);
        }
      });
  };

  const fetchEarnMoreApi = async (platform: string, uuid: string) => {
    setFetchingEarnMore(true);

    await axios
      .get(
        `https://rewards.santabrowser.com/api/quests/list?platform=${platform}&clid=${uuid}`
      )
      .then((res) => {
        if (res.data) {
          setEarnMoreData(res.data);
          const completedQuests = filterCompletedQuests(res.data);
          setCompletedQuests(completedQuests);

          const twitterGroup = res.data.find(
            (group: Group) => group.category.toLowerCase() === "twitter"
          );
          const discordGroup = res.data.find(
            (group: Group) => group.category.toLowerCase() === "discord"
          );
          const telegramGroup = res.data.find(
            (group: Group) => group.category.toLowerCase() === "telegram"
          );

          setTwitterGroup(twitterGroup || null);
          setDiscordGroup(discordGroup || null);
          setTelegramGroup(telegramGroup || null);
          setFetchingEarnMore(false);
        }
      });
  };

  const filterCompletedQuests = (groups: Group[]) => {
    let completedQuests: Quest[] = [];
    groups.forEach((group) => {
      group.quests.forEach((quest) => {
        if (quest.done) {
          completedQuests.push(quest);
        }
      });
    });
    return completedQuests;
  };

  const filterQuestsByKeyword = (quests: Quest[], keyword: string) => {
    return quests.filter((quest) => quest.uni.toLowerCase().includes(keyword));
  };

  useEffect(() => {
    const platform =
      new URL(window.location.href).searchParams.get("platform") || "desktop";
    const uuidFromParam = new URL(window.location.href).searchParams.get(
      "uuid"
    ) as string | null;
    setUuid(uuidFromParam);
    // const uuid11 = new URL(window.location.href).searchParams.get("uuid");
    document.cookie = `user_uuid=${uuidFromParam}; path=/;`;
    if (platform && uuidFromParam) {
      fetchEarnMoreApi(platform, uuidFromParam);
      fetchLeaderboard();
    }
  }, [uuid]);

  const updateQuestStatus = (questUni: string, done: boolean) => {
    if (questUni === "twitterAuth") {
      setTwitterGroup((current) => {
        if (!current) return undefined;
        return {
          ...current,
          quests: current.quests.map((quest) =>
            quest.uni === questUni ? { ...quest, done } : quest
          ),
        };
      });
    } else if (questUni === "discordAuth") {
      setDiscordGroup((current) => {
        if (!current) return undefined;
        return {
          ...current,
          quests: current.quests.map((quest) =>
            quest.uni === questUni ? { ...quest, done } : quest
          ),
        };
      });
    }
  };

  const refreshCompletedQuests = async () => {
    const platform = "desktop";
    const uuidFromParam = new URL(window.location.href).searchParams.get(
      "uuid"
    ) as string | null;

    await axios
      .get(
        `https://rewards.santabrowser.com/api/quests/list?platform=${platform}&clid=${uuidFromParam}`
      )
      .then((res) => {
        if (res.data) {
          const updatedCompletedQuests = filterCompletedQuests(res.data);
          setCompletedQuests(updatedCompletedQuests);
        }
      });
  };

  useEffect(() => {
    socket.on("quest_completed", (data) => {
      const { sender, content } = data;
      const quest = content;
      addPoints(Number(quest.reward));
      updateQuestStatus(quest.uni, true);
      refreshCompletedQuests();
    });
    return () => {
      socket.off("quest_completed");
    };
  }, []);

  return (
    <div className="main">
      <div className="header-region">
        <div className="header-region-content">
          <div className="trophy-icon">
            <img src={cup} alt="cup" width={20} height={20} />
          </div>
          <h3 className="header-region-content-text">Earnings</h3>
        </div>
      </div>

      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        {/* Section */}
        {((fetchingEarnings || fetchingLeaderboard) && (
          <div className="earnings-region">
            <div className="earnings">
              <Skeleton width={"205px"} height={"220px"} borderRadius={"8px"} />
              <Skeleton width={"205px"} height={"220px"} borderRadius={"8px"} />
              <Skeleton width={"205px"} height={"220px"} borderRadius={"8px"} />
              <Skeleton width={"205px"} height={"220px"} borderRadius={"8px"} />
              <Skeleton width={"205px"} height={"220px"} borderRadius={"8px"} />
              <Skeleton width={"205px"} height={"220px"} borderRadius={"8px"} />
            </div>
            <div className="leaderboard">
              <Skeleton height={"90px"} borderRadius={"8px"} />
              <Skeleton
                style={{ marginTop: "12px" }}
                height={"370px"}
                borderRadius={"8px"}
              />
            </div>
          </div>
        )) || (
          <div className="earnings-region">
            <div className="earnings">
              <Earnings
                reward={sample_data[0].total_santa_points[1]}
                earned_points={`${earningsData?.usd_earned || 0}`}
                is_points={false}
                // min_points_to_claim={`${earningsData?.usd_threshold || 0}`}
                min_points_to_claim={"20"}
                uuid={uuid}
              />
              <Earnings
                reward={sample_data[0].total_santa_points[0]}
                earned_points={totalSantaPoints.toLocaleString()}
                is_points={true}
                // min_points_to_claim={`${earningsData?.sp_threshold || 0}`}
                min_points_to_claim={"500"}
                uuid={uuid}
                completedQuests={completedQuests}
              />

              <QuestCard
                quest={quests[0]}
                questGroup={twitterGroup}
                uuid={uuid}
              />
              <ReferEarn data={sample_data[0].refer_and_earn} />
              <QuestCard
                quest={quests[2]}
                uuid={uuid}
                addPoints={addPoints}
                updateCompletedQuests={refreshCompletedQuests}
              />
              <QuestCard
                quest={quests[1]}
                questGroup={discordGroup}
                uuid={uuid}
                addPoints={addPoints}
                updateCompletedQuests={refreshCompletedQuests}
              />
              {/* <div style={{ gridColumn: "1 / -1", height: "100%" }}>
                  <Tasks tasks={sample_data[0].promotions} />
                </div> */}
            </div>
            <div className="leaderboard">
              <Notification />
              <Leaderboard users={leaderboardData.slice(0, 5)} />
            </div>
          </div>
        )}

        {/* Section */}
        {fetchingEarnMore ? (
          <div className="earn-more-region">
            <RegionHeader title={"Earn More"} subtitle={"Santa Quests"} />
            <div className="earn-more">
              <div className="quests">
                <Skeleton
                  height={"350px"}
                  borderRadius={"8px"}
                  style={{ flex: "1" }}
                />
                <Skeleton
                  height={"350px"}
                  borderRadius={"8px"}
                  style={{ flex: "1" }}
                />
                <Skeleton
                  height={"350px"}
                  borderRadius={"8px"}
                  style={{ flex: "1" }}
                />
                <Skeleton
                  height={"350px"}
                  borderRadius={"8px"}
                  style={{ flex: "1" }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="earn-more-region">
            <RegionHeader title={"Earn More"} subtitle={"Santa Quests"} />
            <div className="earn-more">
              <div className="quests">
                {earnMoreData?.map((item: any, index: number) => {
                  if (
                    ["usage", "referral", "download", "default"].includes(
                      item.category.toLowerCase()
                    )
                  ) {
                    return (
                      <Quests
                        key={index}
                        title={item.title}
                        kind={item.id}
                        quests={item.quests}
                        category={item.category}
                        preview={item.preview}
                        icon={item.icon}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        )}

        {((fetchingEarnings || fetchingLeaderboard) && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Skeleton height={"220px"} style={{ flex: "1" }} />
            <Skeleton height={"220px"} style={{ flex: "1" }} />
            <Skeleton height={"220px"} style={{ flex: "1" }} />
          </div>
        )) || (
          <div>
            <div className="social-quests">
              <QuestCard
                quest={quests[0]}
                questGroup={twitterGroup}
                uuid={uuid}
                addPoints={addPoints}
                updateCompletedQuests={refreshCompletedQuests}
              />
              <QuestCard
                quest={quests[1]}
                questGroup={discordGroup}
                uuid={uuid}
                addPoints={addPoints}
                updateCompletedQuests={refreshCompletedQuests}
              />
              <QuestCard
                questGroup={telegramGroup}
                uuid={uuid}
                addPoints={addPoints}
                updateCompletedQuests={refreshCompletedQuests}
              />
            </div>
          </div>
        )}
        {/* Section */}
        <div className="world-quests-region">
          <RegionHeader
            title={"Quests Around the World"}
            subtitle={"Explore more Quest and start earning with Santa"}
            shownav={true}
          />
          <WorldQuests uuid={uuid} />
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Dashboard;
