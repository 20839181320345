import React from "react";
import ReactDOM from "react-dom/client";
import Rewards from "./rewards";
import Dashboard from "./components/dashboard/new-dashboard";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import CallbackComponent from "./components/dashboard/CallbackComponent";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/auth-callback",
    element: <CallbackComponent />,
  },
]);
root.render(
  <React.StrictMode>
    {/* <>
      {uuid ? (
        <Rewards uuid={uuid} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Invalid UUID
        </div>
      )}
    </> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);
