import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PromotedQuest from "./promoted-quest";
import Quest from "./quest";
import Skeleton from "react-loading-skeleton";
interface QuestWorldItemData {
  imageUrl: string;
  domain: string;
  title: string;
  description: string;
  xpPoints: number;
  unlockLevel: number;
}

interface LoyaltyPoints {
  points: number;
}

interface RewardInfo {
  loyaltyPoints: LoyaltyPoints;
}

export interface CampaignNode {
  space: {
    name: string;
    thumbnail: string;
    links: string;
  };
  id: string;
  numberID: number;
  name: string;
  type: string;
  description: string;
  bannerUrl: string;
  info: string;
  seoImage: string;
  rewardName: string;
  distributionType: string;
  rewardInfo: RewardInfo;
}

interface CampaignsEdge {
  node: CampaignNode;
}

interface CampaignsData {
  campaigns: {
    edges: CampaignsEdge[];
  };
}

const WorldQuestsCont = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 19px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const PromotedBox = styled.div`
  cursor: pointer;
`;

interface WorldQuestsProps {
  uuid?: string | null;
}

const WorldQuests: React.FC<WorldQuestsProps> = ({ uuid }) => {
  const [campaigns, setCampaigns] = useState<CampaignNode[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [bannerCampaign, setBannerCampaign] = useState<CampaignNode | null>(
    null
  );
  useEffect(() => {
    const fetchCampaigns = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://rewards.santabrowser.com/proxy/graphigo/query",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `query GetRecommendedSpaces {
              campaigns(input: {first: 20, status: Active, listType: Trending, forAdmin: false, rewardTypes: LOYALTYPOINTS}) {
                edges {
                  node {
                    space {
                      name
                      thumbnail
                      links
                    }
                    id
                    numberID
                    name
                    type
                    description
                    bannerUrl
                    info
                    seoImage
                    thumbnail
                    rewardName
                    distributionType
                    rewardInfo {
                      loyaltyPoints {
                        points
                      }
                    }
                  }
                }
              }
            }`,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();

        if (
          json &&
          json.data &&
          json.data.campaigns &&
          json.data.campaigns.edges
        ) {
          const uniqueSpaces = new Set();
          const filteredCampaigns = json.data.campaigns.edges
            .map((edge: CampaignsEdge) => {
              const node = edge.node;
              // Parse the JSON string in the links field
              node.space.links = JSON.parse(node.space.links).HomePage; // Store only the HomePage URL
              return node;
            })
            .filter((campaign: any) => {
              // Filter campaigns to include only those with a valid HomePage link
              return (
                campaign.space.thumbnail &&
                campaign.space.links &&
                !uniqueSpaces.has(campaign.space.name) &&
                uniqueSpaces.add(campaign.space.name)
              );
            });
          let shuffledCampaigns = filteredCampaigns.sort(
            () => 0.5 - Math.random()
          );
          if (shuffledCampaigns.length > 0) {
            setBannerCampaign(shuffledCampaigns[0]); // Set the first campaign as the banner
            shuffledCampaigns = shuffledCampaigns.slice(1); // Remove the banner campaign from the list
          }
          setCampaigns(shuffledCampaigns);
        } else {
          console.error("Unexpected API response structure:", json);
          setError("Unexpected API response structure");
        }
      } catch (e) {
        console.error("Error fetching campaigns:", e);
        setError("Failed to fetch campaigns");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  const redirectToCampaign = (campaignId: string | undefined) => {
    window.open(`https://galxe.com/space/campaign/${campaignId}`, "_blank");
  };
  return (
    <WorldQuestsCont>
      {loading || !uuid ? (
        <>
          <PromotedBox>
            <Skeleton
              height={"110px"}
              borderRadius={"8px"}
              style={{ flex: "1" }}
            />
          </PromotedBox>
          {Array.from({ length: 9 }).map((_, index) => (
            <Skeleton
              key={index}
              height={"110px"}
              borderRadius={"8px"}
              style={{ flex: "1" }}
            />
          ))}
        </>
      ) : (
        <>
          <PromotedBox onClick={() => redirectToCampaign(bannerCampaign?.id)}>
            <PromotedQuest bannerCampaign={bannerCampaign} />
          </PromotedBox>

          {campaigns.map((campaign, index) => (
            <div key={index} onClick={() => redirectToCampaign(campaign.id)}>
              <Quest campaign={campaign} />
            </div>
          ))}
        </>
      )}
    </WorldQuestsCont>
  );
};

export default WorldQuests;
