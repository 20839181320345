import React from "react";
import styled from "styled-components";

const LeaderboardContainer = styled.div`
  border-radius: 8px;
  background: #262626;
  padding: 16px;
  padding-block-end: 25px;
  flex: 1;
  position: relative;
  z-index: -2;
`;

const LeaderboardTitle = styled.h1`
  color: #f5f5f5;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 95.238% */
  margin-bottom: 6px;
`;

const LeaderboardSubtitle = styled.h1`
  color: #f5f5f5;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 10px;
`;

const LeaderboardList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ListItem = styled.li`
  position: relative;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #171717;
  border: 1px solid transparent;
  background-clip: padding-box;

  &::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: -1;
    border-radius: 8px;
  }

  &:nth-child(1)::after {
    background: linear-gradient(
      to right,
      #ffd96d 0%,
      #a07909 5%,
      #ffbf0be6 10%,
      #b18b1d 14%,
      #755a0dd3 22%,
      #edb515 31%,
      #3e3e3e 65%
    );
  }

  &:nth-child(2)::after {
    background: linear-gradient(
      to right,
      #d7d7d7 0%,
      #bababa 25%,
      #747474 50%,
      #3e3e3e 65%
    );
  }

  &:nth-child(3)::after {
    background: linear-gradient(
      to right,
      #a95f29 0%,
      #c68354 5%,
      #7e4720 17%,
      #b38360 57%,
      #3e3e3e 65%
    );
  }

  &:nth-child(4) {
  }
`;

const Rank = styled.span`
  border-radius: 8px;
  background: #262626;

  display: inline-flex;
  padding: 5px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #f5f5f5;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
  width: 40px;
  white-space: nowrap;
`;

const Username = styled.span`
  color: #f5f5f5;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 123.529% */
  margin-left: 29.75px;
  margin-right: 20px;
  flex: 1;
`;

const Points = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  > span:first-of-type {
    color: #f5f5f5;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-right: 0.5em;
  }

  > span:last-of-type {
    color: #d44646;
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 158.333% */
    white-space: nowrap;
  }
`;

type Leaders = { account: string; points: number };

const Leaderboard = ({ users }: { users: Leaders[] }) => {
  const unreveal = (account: string): string => {
    if (account.length < 5) return account;
    return `XXXXXXXXX...${account.substring(
      account.length - 5,
      account.length
    )}`;
  };

  return (
    <LeaderboardContainer>
      <LeaderboardTitle>Leaderboard</LeaderboardTitle>
      <LeaderboardSubtitle>Compete to earn more rewards</LeaderboardSubtitle>
      <LeaderboardList>
        {users.map((user, index) => (
          <ListItem key={index}>
            <Rank># {index + 1}</Rank>
            <Username>{unreveal(user.account)}</Username>
            <Points>
              <span>{user.points}</span> <span>pts</span>
            </Points>
          </ListItem>
        ))}
      </LeaderboardList>
    </LeaderboardContainer>
  );
};

export default Leaderboard;
