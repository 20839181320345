import React, { ReactNode } from "react";
import styled from "styled-components";

const actionWords: any = {
  usage: "Activate Now",
  referral: "Invite Now",
  download: "Download Now",
  default: "Set as Default",
};

const highlightColors: any = {
  "first referral": "#FFBE05",
  "10 referrals": "#FFBE05",
  "100 referrals": "#FFBE05",
  "1 hr": "#B946FF",
  "10 hrs": "#B946FF",
  "100 hrs": "#B946FF",
  install: "#F16430",
  default: "#308923",
};

const QuestsContainer = styled.div`
  border-radius: 8px;
  background: #262626;
  padding: 7px;
  max-height: 445px;
  flex: 1;
`;

const Header = styled.div<{ $header_image: string }>`
  border-radius: 8px;
  background: linear-gradient(92deg, #b13030 -17.99%, #4b1414 94.01%);
  display: flex;
  padding: 16px;
  background: url(${({ $header_image }) => $header_image}) no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: space-between;
`;

const QuestsTitle = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const QuestsCampaigns = styled.div`
  color: #f5f5f5;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Content = styled.div`
  margin-top: 16px;
  overflow: auto;
  position: relative;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  z-index: 1;
`;

const Quest = styled.div`
  position: relative;
  border-radius: 10px;
  border: 1px solid transparent;
  background: #1f1f1f;
  cursor: pointer;
  background-clip: padding-box;
  transition: all 0.3s ease;

  &::after {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 10px;
    content: "";
    z-index: -1;
    background-image: radial-gradient(
      circle at center,
      #1a1a1a 0%,
      #6f6f6f 5%,
      #1a1a1a 100%
    );
    opacity: 0; // Start fully transparent
  }

  &:hover {
    background-color: #1b1b1b;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    &::after {
      opacity: 1;
    }
  }
`;

const QuestContent = styled.div`
  padding: 9px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
`;

const QuestHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const QuestEarnings = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const QuestDuration = styled.div`
  border-radius: 8px;
  border: 1px solid #29b6f6;
  background: rgba(41, 182, 246, 0.3);
  display: flex;
  padding: 8px 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
`;

export const QuestPoints = styled.div`
  border-radius: 8px;
  border: 1px solid #7a7a7a;
  background: #181818;
  display: flex;
  padding: 8px 11px;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;

const QuestReferrals = styled.div`
  color: #ffbe05;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;

const QuestDescription = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`;

const QuestStatus = styled.div`
  color: #29b6f6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const QuestRefer = styled.div`
  color: #29b6f6;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
  display: none;
`;

const HighlightedText = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

type QuestProps = {
  children?: ReactNode;
  title: string;
  kind: string;
  category: string;
  preview: string;
  icon: string;
  quests: [];
};

const Quests = (props: QuestProps) => {
  const actionWord = actionWords[props?.category.toLowerCase()];

  const parseDescription = (description: string) => {
    const regex =
      /\b(install|default|first referral|10 referrals|santa|100 referrals|1 hr|10 hrs|100 hrs)\b/gi;
    const parts = description.split(regex);

    return parts.map((part, index) => {
      if (
        part.toLowerCase() === "install" ||
        part.toLowerCase() === "default" ||
        part.toLowerCase() === "first referral" ||
        part.toLowerCase() === "10 referrals" ||
        part.toLowerCase() === "100 referrals" ||
        part.toLowerCase() === "1 hr" ||
        part.toLowerCase() === "10 hrs" ||
        part.toLowerCase() === "100 hrs"
      ) {
        const color = highlightColors[part.toLowerCase()];
        return (
          <HighlightedText key={`highlight-${index}`} color={color}>
            {part}
          </HighlightedText>
        );
      } else if (part.toLowerCase() === "santa") {
        return (
          <em key={`em-${index}`} style={{ fontWeight: "600" }}>
            {part}
          </em>
        );
      }
      return <span key={`span-${index}`}>{part}</span>;
    });
  };
  return (
    <QuestsContainer>
      <Header
        $header_image={`https://rewards.santabrowser.com/api/upload/${props.preview}`}
      >
        <div>
          <QuestsTitle>{props.title}</QuestsTitle>
          <QuestsCampaigns>{props.quests.length} Campaigns</QuestsCampaigns>
        </div>
        <img
          src={`https://rewards.santabrowser.com/api/upload/${props.icon}`}
          alt={`${props.title}`}
          width={47}
          height={47}
          style={{ objectFit: "contain" }}
        />
      </Header>
      <Content>
        {props.quests
          .sort((a: any, b: any) => a.reward - b.reward)
          .map((quest: any, index: number) => {
            return (
              <Quest key={index}>
                <QuestContent>
                  <QuestHeader>
                    <QuestEarnings>
                      {/* <QuestDuration>Daily</QuestDuration> */}
                      <QuestPoints>
                        {(+quest.reward).toLocaleString()} pts
                      </QuestPoints>
                      {/* <QuestStatus>{actionWord}</QuestStatus> */}
                      {/* <QuestReferrals>{quest.title}</QuestReferrals> */}
                    </QuestEarnings>
                    {/* <QuestRefer>Refer Now</QuestRefer> */}
                  </QuestHeader>
                  <QuestDescription>
                    {parseDescription(quest.description)}
                  </QuestDescription>
                </QuestContent>
              </Quest>
            );
          })}
      </Content>
    </QuestsContainer>
  );
};

export default Quests;
