import React from "react";
import styled from "styled-components";
import clock_url from "../../assets/clock.svg";
import nft_sample_url from "../../assets/nft_sample.png";
import { CampaignNode } from "./world-quests";

const Container = styled.div`
  height: 100%;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 110px;
  border: 1px solid #1f1f1f;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-inline-end: 11.5px;
  padding-block-start: 12.03px;
  padding-inline-start: 13px;
  padding-block-end: 16.62px;
`;

const Tag = styled.div`
  border-radius: 5px;
  border: 1px solid #7a7a7a;
  background: #181818;
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: capitalize;
  align-self: flex-end;
`;

const Details = styled.div`
  border-radius: 8px;
  border: 1px solid #60a5fa;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  align-self: flex-start;
  max-width: 250px;
`;

const TimeSection = styled.div`
  display: flex;
  margin-right: 19.85px;
  width: 100%;
`;

const TextPrimary = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
`;

const TextDescription = styled.div`
  width: inherit;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
`;
interface PromotedQuestProps {
  bannerCampaign: CampaignNode | null;
}
const PromotedQuest: React.FC<PromotedQuestProps> = ({ bannerCampaign }) => {
  return (
    <Container
      style={{ backgroundImage: `url(${bannerCampaign?.space.thumbnail})` }}
    >
      <Content>
        <Tag>Promoted</Tag>
        <Details>
          {/* <img src={clock_url} alt="clock" /> */}
          <TimeSection>
            {/* <TextPrimary>{bannerCampaign?.space.name}</TextPrimary> */}
            <TextDescription>{bannerCampaign?.name}</TextDescription>
          </TimeSection>
          {/* <TimeSection>
            <TextPrimary>17.53 ETH</TextPrimary>
            <TextDescription>Highest Bid</TextDescription>
          </TimeSection> */}
        </Details>
      </Content>
    </Container>
  );
};

export default PromotedQuest;
