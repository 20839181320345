import styled from "styled-components";
import right_arrow_url from "../../assets/right_arrow.svg";
import axios from "axios";
import { Quest } from "../dashboard/types";
import { useEffect, useState } from "react";
import rightChevron from "../../assets/right_cheveron.svg";
import downCheveron from "../../assets/down_cheveron.svg";
const Card = styled.div`
  border-radius: 8px;
  background: #262626;
  /* max-width: 205px; */
  height: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid transparent;
  &:hover {
    background: #1e1e1e;
    border: 1px solid #323232;
  }
`;

const Container = styled.div`
  padding-block-start: 20px;
  padding-block-end: 8px;
  padding-inline-start: 13px;
  padding-inline-end: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const TopContent = styled.div`
  display: flex;
  gap: 25px;
  align-items: flex-start;
`;

const TopContentText = styled.div``;

const TopContentTitle = styled.div`
  color: #d44646;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const TopContentSubtitle = styled.div`
  color: #f5f5f5;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* margin-top: 36px; */
`;

const Points = styled.div`
  span {
    display: inline-block;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    line-height: 16px;
    color: #f5f5f5;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
  }
`;

const ClaimButton = styled.div`
  display: flex;
  flex-direction: column;
  button {
    border-radius: 4px;
    background: #ca4343;
    border: none;
    text-transform: uppercase;
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    padding: 10px 64px;

    :disabled {
      opacity: 0.7;
    }
  }
  div {
    color: #9d9d9d;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 121.429% */
    margin-top: 6px;
  }
  div > span {
    color: #9d9d9d;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  z-index: 1000;
`;

const ModalContent = styled.div`
  border-radius: 5px 0px 0px 5px;
  background: linear-gradient(180deg, #181818 21.66%, #1b1b1b 100%);
  box-shadow: -10px 4px 25px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 25px 25px 20px 25px;
  max-width: 500px;
  width: 90%;
  height: 100%;
`;

const ModalBackButton = styled.button`
  margin-top: 15px;
  width: 22px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #991e0d;
  background: #de5947;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const ModalTitle = styled.h2`
  color: #d44646;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const ModalSubTitle = styled.h4`
  color: rgba(245, 245, 245, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ModalSort = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 8px;
`;

const SortTitle = styled.h6`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ModalSortSelect = styled.div`
  position: relative;
  color: #a3a3a3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const SortByButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  /* gap: 45px; */
  border-radius: 7px;
  background: #272727;
  min-width: 130px;
`;

const Dropdown = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  gap: 3px;
  position: absolute;
  background-color: #272727;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 4px 10px;
  z-index: 1;
  margin-top: -5px;
  border-top: 1px solid #333;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  div {
    &:hover {
      color: #fff;
    }
  }
`;

const QuestGroupDate = styled.div`
  width: fit-content;
  padding: 0 8px;
  display: flex;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  border-radius: 7px;
  background: rgba(37, 37, 37, 0.8);
  margin-bottom: 4px;
`;

const QuestBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid #2e2e2e;
  background: #272727;
  height: 68px;
  flex-shrink: 0;
  padding: 0 10px;
`;

const QuestLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1.1;
`;
const QuestTitle = styled.h4`
  color: #f5f5f5;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const QuestDate = styled.p`
  color: #f5f5f5;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  flex: 2;
  text-align: center;
`;
const QuestReward = styled.h4`
  color: #f5f5f5;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  flex: 1;

  span {
    color: #f5f5f5;
    leading-trim: both;
    text-edge: cap;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }
`;

const ChevronIcon = styled.img<{ open: boolean }>`
  transition: transform 0.3s ease;
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalBackButton onClick={onClose}>
        <img src={rightChevron} alt="" />
      </ModalBackButton>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalBackdrop>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

type RewardType = {
  title: string;
  sub_title: string;
  claim_action: string;
};

interface GroupedQuests {
  [monthYear: string]: Quest[];
}

const Earnings = ({
  reward,
  earned_points,
  is_points,
  min_points_to_claim,
  uuid,
  completedQuests,
}: {
  reward: RewardType;
  earned_points: string;
  is_points: boolean;
  min_points_to_claim: string;
  uuid: string | null;
  completedQuests?: Quest[];
}) => {
  const formattedEarnedPoints = Number(earned_points).toLocaleString();
  const formattedMinPointsToClaim =
    Number(min_points_to_claim).toLocaleString();
  const isEligible = Number(earned_points) >= Number(min_points_to_claim);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Date"); // Default selected option
  const [groupedQuests, setGroupedQuests] = useState<GroupedQuests>({});
  const [sortCriteria, setSortCriteria] = useState<"date" | "reward">("date");

  useEffect(() => {
    if (completedQuests) {
      setGroupedQuests(groupQuestsByMonth(completedQuests));
    }
  }, [completedQuests]);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const handleClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (!isEligible || !uuid) return;
    console.log("uuid:: ", uuid);
    const endpoint = is_points
      ? `/claim_points/${uuid}`
      : `/claim_rewards/${uuid}`;

    try {
      const response = await axios.post(
        `https://rewards.santabrowser.com${endpoint}`
      );
      console.log("Claim successful:", response.data);
      alert(
        `Successfully claimed ${
          is_points ? "points" : "rewards"
        }! Check your wallet`
      );
    } catch (error: any) {
      console.error("Error when claiming:", error);
      alert(`Claim failed: ${error.message}`);
    }
  };
  const toggleModal = (event: React.MouseEvent) => {
    const message = {
      type: "MODAL_STATE_CHANGE",
      modalOpen: !modalIsOpen,
    };
    window?.top?.postMessage(JSON.stringify(message), "*");
    setModalIsOpen(!modalIsOpen);
  };

  const groupQuestsByMonth = (quests: Quest[]): GroupedQuests => {
    const grouped: GroupedQuests = {};
    quests
      .sort(
        (a, b) =>
          new Date(a.completed_at).getTime() -
          new Date(b.completed_at).getTime()
      )
      .forEach((quest) => {
        const date = new Date(quest.completed_at);
        const monthYear = date.toLocaleString("default", {
          month: "short",
          year: "numeric",
        });
        if (!grouped[monthYear]) {
          grouped[monthYear] = [];
        }
        grouped[monthYear].push(quest);
      });
    return grouped;
  };

  const sortQuests = (
    quests: Quest[],
    criteria: "date" | "reward"
  ): Quest[] => {
    return [...quests].sort((a, b) => {
      if (criteria === "reward") {
        return parseInt(b.reward) - parseInt(a.reward); // Assumes 'reward' is a sortable integer
      }
      return (
        new Date(a.completed_at).getTime() - new Date(b.completed_at).getTime()
      );
    });
  };

  return (
    <Card onClick={toggleModal}>
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <ModalHeader>
          <ModalTitle>
            {is_points ? "Total Santa Points Earned" : "Shopping & PlayWall"}
          </ModalTitle>
          <ModalSubTitle>
            {is_points
              ? "Detailed list of total points earned"
              : "Detailed list of rewards and shopping cashback"}
          </ModalSubTitle>
          <ModalSort>
            <SortTitle>Sort by</SortTitle>

            <ModalSortSelect style={{ position: "relative" }}>
              <SortByButton onClick={() => setDropdownOpen(!dropdownOpen)}>
                {selectedOption}{" "}
                <ChevronIcon
                  src={downCheveron}
                  alt="Toggle dropdown"
                  open={dropdownOpen}
                />
              </SortByButton>
              <Dropdown open={dropdownOpen}>
                <div
                  onClick={() => {
                    handleOptionClick("Date");
                    setSortCriteria("date");
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: selectedOption === "Date" ? "600" : "initial",
                    textDecoration:
                      selectedOption === "Date" ? "underline" : "unset",
                  }}
                >
                  Date
                </div>
                <div
                  onClick={() => {
                    handleOptionClick("Values");
                    setSortCriteria("reward");
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: selectedOption === "Values" ? "600" : "initial",
                    textDecoration:
                      selectedOption === "Values" ? "underline" : "unset",
                  }}
                >
                  Values
                </div>
              </Dropdown>
            </ModalSortSelect>
          </ModalSort>
        </ModalHeader>

        {Object.keys(groupedQuests).map((monthYear) => (
          <div key={monthYear} style={{ marginTop: "20px" }}>
            <QuestGroupDate>{monthYear}</QuestGroupDate>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {sortQuests(groupedQuests[monthYear], sortCriteria).map(
                (quest) => (
                  <QuestBox key={quest.id}>
                    <QuestLeft>
                      <img
                        src={`https://rewards.santabrowser.com/api/upload/${quest.icon}`}
                        alt="quest icon"
                        width={32}
                        height={32}
                        style={{ objectFit: "contain" }}
                      />
                      <QuestTitle>{quest.title}</QuestTitle>
                    </QuestLeft>
                    <QuestDate>{quest.completed_at.split("T")[0]}</QuestDate>
                    <QuestReward>
                      {quest.reward} <span>pts</span>
                    </QuestReward>
                  </QuestBox>
                )
              )}
            </div>
          </div>
        ))}
      </Modal>
      <Container>
        <TopContent>
          <TopContentText>
            <TopContentTitle>{reward.title}</TopContentTitle>
            <TopContentSubtitle>{reward.sub_title}</TopContentSubtitle>
          </TopContentText>
        </TopContent>
        <BottomContent>
          <Points>
            {is_points ? (
              <span>
                {earned_points}
                <span
                  style={{
                    fontSize: "14px",
                    color: "#d44646",
                    fontWeight: "400",
                  }}
                >
                  pts
                </span>
              </span>
            ) : (
              <span>${formattedEarnedPoints}</span>
            )}
          </Points>
          <ClaimButton>
            <button
              onClick={handleClaim}
              style={{ opacity: isEligible ? "1" : "0.3" }}
            >
              Claim
            </button>
            <div>
              Min {!is_points && <span>$</span>}
              <span>{formattedMinPointsToClaim}</span>
              {is_points && <span>pts</span>} to Claim
            </div>
          </ClaimButton>
        </BottomContent>
      </Container>
    </Card>
  );
};

export default Earnings;
